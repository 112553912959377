// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-archieves-tsx": () => import("./../src/pages/archieves.tsx" /* webpackChunkName: "component---src-pages-archieves-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-page-tsx": () => import("./../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-paginated-blog-index-tsx": () => import("./../src/templates/paginated-blog-index.tsx" /* webpackChunkName: "component---src-templates-paginated-blog-index-tsx" */)
}

